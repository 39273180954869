import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/EditProduct.css';  // Assuming the styles for AddProduct are here

function EditProduct() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    name: '',
    category: '',
    description: '',
    images: []
  });
  const [newImages, setNewImages] = useState([]);
  const [status, setStatus] = useState(''); // State to track the form submission status

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://ease-products-upload.onrender.com/api/preload/getProduct/${id}`);
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product', error);
      }
    };
    fetchProduct();
  }, [id]);

  const onChange = (e) => setProduct({ ...product, [e.target.name]: e.target.value });

  const onFileChange = (e) => setNewImages(e.target.files);

  const onSubmit = async (e) => {
    e.preventDefault();
    setStatus('editing'); // Set status to 'editing' when form is submitted
    const formData = new FormData();
    formData.append('name', product.name);
    formData.append('category', product.category);
    formData.append('description', product.description);

    for (let i = 0; i < newImages.length; i++) {
      formData.append('images', newImages[i]);
    }

    try {
      await axios.put(`https://ease-products-upload.onrender.com/api/preload/updateProduct/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setStatus('success'); // Set status to 'success' if update is successful
      setTimeout(() => navigate('/view-products'), 2000); // Navigate to view products page after 2 seconds
    } catch (error) {
      console.error('Error updating product', error);
      setStatus('failed'); // Set status to 'failed' if update fails
    }
  };

  return (
    <div className="form-container">
      <h1>Edit Product</h1>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label>Name</label>
          <input type="text" name="name" value={product.name} onChange={onChange} />
        </div>
        <div className="form-group">
          <label>Category</label>
          <input type="text" name="category" value={product.category} onChange={onChange} />
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea name="description" value={product.description} onChange={onChange}></textarea>
        </div>
        <div className="form-group">
          <label>Images</label>
          <input type="file" name="images" multiple onChange={onFileChange} />
        </div>
        <button type="submit" disabled={status === 'editing'}>
          {status === 'editing' ? 'Editing...' : 'Update Product'}
        </button>
      </form>
      {status === 'success' && <p>Product updated successfully!</p>}
      {status === 'failed' && <p>Failed to update product. Please try again.</p>}
    </div>
  );
}

export default EditProduct;
