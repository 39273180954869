import React, { useState } from 'react';
import axios from 'axios';
import '../css/AddProduct.css';

const AddProduct = () => {
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');

  const categories = [
    'Books',
    'Clothing',
    'Drinks',
    'Electronics',
    'Food',
    'Fruit',
    'Furniture',
    'Groceries',
    'Home Appliances',
    'Jewelry',
    'Shoes',
    'Snacks',
    'Sports Equipment',
    'Toys',
    'Other'
  ].sort();

  const handleImageUpload = (e) => {
    setImages(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus('');

    const formData = new FormData();
    formData.append('name', name);
    formData.append('category', category);
    images.forEach((image) => {
      formData.append('images', image);
    });
    formData.append('description', description);

    try {
      const response = await axios.post('https://ease-products-upload.onrender.com/api/preload/addProduct', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setStatus('Product added successfully');
      setName('');
      setCategory('');
      setImages([]);
      setDescription('');
    } catch (error) {
      setStatus('Error adding product');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="add-product-form" onSubmit={handleSubmit}>
      <h2>Add Product</h2>
      <div className="form-group">
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label>Category:</label>
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          required
        >
          <option value="" disabled>Select a category</option>
          {categories.map((cat, index) => (
            <option key={index} value={cat}>{cat}</option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label>Images:</label>
        <input
          type="file"
          multiple
          onChange={handleImageUpload}
        />
        <div className="image-previews">
          {images.map((image, index) => (
            <img key={index} src={URL.createObjectURL(image)} alt={`Preview ${index}`} width="100" />
          ))}
        </div>
      </div>
      <div className="form-group">
        <label>Description:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <button type="submit" disabled={loading} className='add-button'>
        {loading ? 'Adding Product...' : 'Add Product'}
      </button>
      {status && <p>{status}</p>}
    </form>
  );
};

export default AddProduct;
