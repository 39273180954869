import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/ViewProducts.css';

const ViewProducts = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://ease-products-upload.onrender.com/api/preload/getProducts');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products', error);
      }
    };

    fetchProducts();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://ease-products-upload.onrender.com/api/preload/removeProduct/${id}`);
      setProducts(products.filter(product => product._id !== id));
    } catch (error) {
      console.error('Error deleting product', error);
    }
  };

  return (
    <div className="view-products">
      <h2>View Products</h2>
      <div className="products-list">
        {products.map((product) => (
          <div key={product._id} className="product-card">
            <h3>{product.name}</h3>
            <p>Category: {product.category}</p>
            <div className="image-preview">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Product ${index}`}
                  width="100"
                />
              ))}
            </div>
            <p>{product.description}</p>
            <div className="product-actions">
              <Link to={`/edit-product/${product._id}`} className="edit-button">Edit</Link>
              <button  onClick={() => handleDelete(product._id)} className="delete-button">Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewProducts;
