import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import AddProduct from './component/AddProduct';
import ViewProducts from './component/ViewProducts';
import EditProduct from './component/EditProduct';
import './App.css';

function App() {
  return (
    <Router>
      <nav className="navbar">
        <NavLink to="/" className="nav-link" activeClassName="active-link">Add Product</NavLink>
        <NavLink to="/view-products" className="nav-link" activeClassName="active-link">View Products</NavLink>
      </nav>
      <Routes>
        <Route path="/" element={<AddProduct />} />
        <Route path="/view-products" element={<ViewProducts />} />
        <Route path="/edit-product/:id" element={<EditProduct />} />
      </Routes>
    </Router>
  );
}

export default App;
